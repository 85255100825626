<!--
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-06 09:33:16
 * @LastEditTime: 2021-03-02 11:09:14
 -->
<template>
  <div
    v-if="show"
    :class="`float_btn_dialog_wrp ${ type }`"
  >
    <div
      class="dialog"
    >
      <div
        :is="type"
        @handleClose="handleClose"
      />
    </div>
  </div>
</template>
<script>
import wechat from './wechat';
import phone from './phone';
import pencil from './pencil';

export default {
  name: 'Dialogwrp',
  // eslint-disable-next-line vue/no-unused-components
  components: { wechat, phone, pencil },
  props: {
    type: {
      type: String,
      default: 'wechart',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose(type) {
      this.$emit('handleClose', type);
    },
  },
};
</script>
<style lang="scss">
  .float_btn_dialog_wrp {
    position: absolute;
    bottom: 0;
    left: -60px;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: auto;
    .dialog {
      background: #ffffff;
      box-shadow:0px 5px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius:20px 20px 0px 20px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &.phone,
    &.pencil {
      > .dialog {
        border-radius:30px 30px 0px 30px;
      }
    }
  }
</style>
