<!--
 * @Descripttion: header头部切换公共组件
 * @Author: 张明尧
 * @Date: 2021-02-03 15:48:19
 * @LastEditTime: 2021-03-18 18:00:52
-->
<template>
  <div :style="`background: ${homePageStyle.defaultStyle};`" class="ktdn-header" :class="showBox && 'ktdn-header-boxShadow'">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="11" :offset="1">
        <div class="header-main header-left font-size-26">
          <div class="icon-title">
            <div class="icon-title-font"><i :style="`color: ${homePageStyle.iconColor}`" class="jeicon jeicon-elephant-q-on"></i></div>
            <div :style="`color: ${homePageStyle.colorDefault}`" class="icon-title-text">企业大脑</div>
          </div>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="header-main header-right">
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col v-for="(menu, _menu) in menuData" :key="_menu" :span="4">
              <div :style="!menu.activeStatus && `color: ${homePageStyle.colorDefault}`" @click="clickHref(menu.href)" :class="menu.activeStatus && 'activeHref'" class="href-text">
                <span :style="menu.activeStatus && homePageData.name != 'homePage' && 'color: #689afd'">
                  {{ menu.name }}
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          name: '首页',
          vuePage: 'homePage',
          href: '/home',
          activeStatus: true,
        },
        {
          name: '产品',
          vuePage: 'productPage',
          href: '/product',
          activeStatus: false,
        },
        {
          name: '哲学',
          href: '/philosophy',
          vuePage: 'philosophyPage',
          activeStatus: false,
        },
        // {
        //   name: '体验',
        //   href: '/experience',
        //   vuePage: 'experiencePage',
        //   activeStatus: false,
        // },
        {
          name: '学院',
          href: '/school',
          vuePage: 'schoolPage',
          activeStatus: false,
        },
        {
          name: '购买',
          href: '/buy',
          vuePage: 'buyPage',
          activeStatus: false,
        },
        {
          name: '关于',
          href: '/about',
          vuePage: 'aboutPage',
          activeStatus: false,
        },
      ],
      homePageShow: true,
      showBox: false,
      homePageData: {},
    }
  },
  watch:{
    $route:{
      handler(val){
        this.filterMenuActive(val);
        this.homePageData = val;
        document.body.scrollIntoView({behavior:'smooth', block: 'start'});
      },
      immediate: true
    }
  },
  computed: {
    // 针对不同路由页面显示样式不同
    homePageStyle() {
      const defaultStyle = '#0e56e2';
      const styleObj = {
        homePage: {
          defaultStyle: !this.showBox && '#F9FCFF' || 'rgba(255,255,255,.9)', // 默认显示背景样式
          colorDefault: '#3f3f3f', // 字体的默认样式
          iconColor: !this.showBox && '#0358FD' || '#0358FD', // 头部图标的颜色
          colorActive: '#0358FD', // 字体点击后的样式效果
        },
        productPage: {
          defaultStyle: !this.showBox && defaultStyle || 'rgba(255,255,255,.9)', // 默认显示背景样式
          colorDefault: !this.showBox && '#ffffff' || '#3f3f3f', // 字体的默认样式
          iconColor: !this.showBox && '#ffffff' || '#0358FD', // 头部图标的颜色
          colorActive: 'hsl(0deg 0% 35% / 50%);', // 字体点击后的样式效果
        },
        philosophyPage: {
          defaultStyle: !this.showBox && defaultStyle || 'rgba(255,255,255,.9)', // 默认显示背景样式
          colorDefault: !this.showBox && '#ffffff' || '#3f3f3f', // 字体的默认样式
          iconColor: !this.showBox && '#ffffff' || '#0358FD', // 头部图标的颜色
          colorActive: 'hsl(0deg 0% 35% / 50%);', // 字体点击后的样式效果
        },
        experiencePage: {
          defaultStyle: !this.showBox && defaultStyle || 'rgba(255,255,255,.9)', // 默认显示背景样式
          colorDefault: !this.showBox && '#ffffff' || '#3f3f3f', // 字体的默认样式
          iconColor: !this.showBox && '#ffffff' || '#0358FD', // 头部图标的颜色
          colorActive: 'hsl(0deg 0% 35% / 50%);', // 字体点击后的样式效果
        },
        schoolPage: {
          defaultStyle: !this.showBox && defaultStyle || 'rgba(255,255,255,.9)', // 默认显示背景样式
          colorDefault: !this.showBox && '#ffffff' || '#3f3f3f', // 字体的默认样式
          iconColor: !this.showBox && '#ffffff' || '#0358FD', // 头部图标的颜色
          colorActive: 'hsl(0deg 0% 35% / 50%);', // 字体点击后的样式效果
        },
        buyPage: {
          defaultStyle: !this.showBox && defaultStyle || 'rgba(255,255,255,.9)', // 默认显示背景样式
          colorDefault: !this.showBox && '#ffffff' || '#3f3f3f', // 字体的默认样式
          iconColor: !this.showBox && '#ffffff' || '#0358FD', // 头部图标的颜色
          colorActive: 'hsl(0deg 0% 35% / 50%);', // 字体点击后的样式效果
        },
        aboutPage: {
          defaultStyle: !this.showBox && defaultStyle || 'rgba(255,255,255,.9)', // 默认显示背景样式
          colorDefault: !this.showBox && '#ffffff' || '#3f3f3f', // 字体的默认样式
          iconColor: !this.showBox && '#ffffff' || '#0358FD', // 头部图标的颜色
          colorActive: 'hsl(0deg 0% 35% / 50%);', // 字体点击后的样式效果
        },
      };
      return styleObj[this.homePageData.name]
    }
  },
  created() {
    window.addEventListener('scroll', this.addEventFunc, false);
  },
  methods: {
    addEventFunc() {
      if(document.documentElement.scrollTop > 200) {
        this.showBox = true;
        this.homePageShow = false;
      }else if(this.showBox && document.documentElement.scrollTop < 200) {
        this.showBox = false;
        this.homePageShow = true;
      }
    },
    // 改变菜单当前点击的样式
    filterMenuActive(val) {
      this.menuData = this.menuData.filter(res => {
        val.name == res.vuePage ? (res.activeStatus = true) : (res.activeStatus = false);
        return res;
      })
    },
    openWindow(url) {
      if(!url) return;
      window.open(url);
    },
    /**
     * 点击菜单跳转指定地址
     * href 需要跳转的地址
     */
    clickHref(href) {
      if(!href) return;
      this.$router.push({path: href});
    }
  }
}
</script>

<style lang="scss" scoped>
  .ktdn-header-boxShadow {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 10px 0px;
  }
  .ktdn-header {
    height: 80px;
    background: rgba(255,255,255,.9);
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    .href-text {
      cursor: pointer;
      text-align: center;
      font-size: 18px;
      &:hover {
        color: #0358FD;
        transition: 0.2s linear;
      }
    }
    .activeHref {
      color: #0358FD;
    }
    .header-main {
      line-height: 80px;
      color: #3f3f3f;
      .icon-title {
        display: flex;
        align-items: center;
        .borderstyle {
          height: 35px;
          width: 2px;
          background: #0358FD;
          margin-left: 10px;
        }
        .icon-title-jepaas {
          height: 50px;
          padding-left: 10px;
          .jepaas-text {
            height: 25px;
            // line-height: 20px
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .icon-title-font {
          width: 40px;
          height: 40px;
          // background: #0358FD;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: #0358FD;
          i {
            font-size: 30px;
          }
        }
        .icon-title-text {
          margin-left: 10px;
          font-size: 25px;
          color: #3f3f3f;
        }
      }
      .logo {
        height: 40px;
        width: auto;
      }
    }
  }
</style>