<!--
 * @Descripttion: footer尾部的公共组件
 * @Author: 张明尧
 * @Date: 2021-02-03 15:48:49
 * @LastEditTime: 2021-03-17 16:17:48
-->
<template>
  <div class="footer-main">
    <div class="footer-content">
      <div class="icon-title">
        <div class="icon-title-font"><i class="jeicon jeicon-elephant-q-on"></i></div>
        <div class="icon-title-text">企业大脑</div>
      </div>
      <div class="footer-content-main">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12">
            <div class="margin-top-30 font-size-16">联系我们</div>
            <div>公 司：北京凯特伟业科技有限公司 </div>
            <div>电 话：010-82809807 / 400-0999-235</div>
            <div>联系人：云先生 18610941078 </div>
            <div>地 址：北京市海淀区金澳国际写字楼16层 </div>
          </el-col>
          <el-col :span="3">
            <div class="padding-top-20">
              关注我们
            </div>
            <div class="margin-top-20">
              <img class="erweima-img" src="../assets/ktdn/erweima.png" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="jingbei">
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >京ICP备18023569号-6</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-main {
    background: #3D434F;
    color: #FFFFFF;
    box-sizing: border-box;
    padding-bottom: 30px;
    .font-size-16 {
      font-size: 16px;
    }
    .jingbei {
      text-align: center;
      font-size: 13px;
      margin-top: 20px;
      a{
        color: #fff;
      }
    }
    .erweima-img {
      width: 120px;
      height: auto;
    }
    .icon-title {
      display: flex;
      align-items: center;
      .icon-title-font {
        width: 40px;
        height: 40px;
        // background: #0358FD;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #ffffff;
        i {
          font-size: 25px;
        }
      }
      .icon-title-text {
        margin-left: 10px;
        font-size: 20px;
        color: #FFFFFF;
      }
    }
    .footer-content {
      width: 1400px;
      margin: 0 auto;
      padding: 30px 0;
      border-bottom: 2px solid #596274;
      .footer-content-main {
        font-size: 13px;
        line-height: 2.2;
      }
      .footer-logo {
        height: 40px;
        width: auto;
      }
    }
  }
</style>