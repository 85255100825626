<!--
 * @Descripttion: 
 * @Author: 张明尧
 * @Date: 2021-03-02 10:41:36
 * @LastEditTime: 2021-03-02 11:09:34
-->
<template>
  <div class="float_btn_wechart">
    <p>扫码关注公众号</p>
    <img
      :src="qr_qrcode"
      alt=""
    >
  </div>
</template>

<script>
import qr_qrcode from '@/assets/wx_qrcode.png';

export default {
  name: 'FloatBtnWeChart',
  data() {
    return {
      qr_qrcode,
    };
  },
};
</script>

<style lang="scss" scoped>
  .float_btn_wechart {
    padding: 20px;
    > p {
      margin: 0;
      color: #333333;
      font-size: 18px;
    }
    > img {
      width: 140px;
      height: 140px;
      margin-top: 20px;
    }
  }
</style>
