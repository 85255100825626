<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-03-26 13:54:59
 * @LastEditTime: 2021-03-02 11:09:08
 -->
<template>
  <div class="float_btn_dialog_title">
    {{ text }}
    <i
      class="jeicon jeicon-error close"
      @click="handleClose"
    />
  </div>
</template>
<script>
export default {
  name: 'FloatBtnDialogTitle',
  props: {
    text: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },
  },
};
</script>
<style lang="scss" scoped>
  .float_btn_dialog_title {
    margin: 0;
    width: 100%;
    font-size: 22px;
    color: #333333;
    position: relative;
    text-align: left;
    padding-bottom: 30px;
    border-bottom: 1px solid #cccccc;
    font-weight: bold;
    > .close {
      position: absolute;
      top: 5px;
      right: 0;
      font-size: 18px;
      color: #999999;
      cursor: pointer;
    }
  }
</style>
