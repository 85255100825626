<template>
  <div class="float_btn_pencil">
    <div class="float_btn_pencil_content">
      <dialogtitle
        :text="'留言咨询'"
        @handleClose="handleClose"
      />
      <div class="form_wrp">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="姓名"
                prop="name"
              >
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入您的姓名"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职务">
                <el-input
                  v-model="ruleForm.duty"
                  placeholder="请输入您的职务"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="12">
              <el-form-item
                label="电话"
                prop="phone"
              >
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="请输入联系电话"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱">
                <el-input
                  v-model="ruleForm.email"
                  placeholder="请输入邮箱地址"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20 el_li"
            :gutter="20"
          >
            <el-col :span="24">
              <el-row>
                <el-col :span="10">
                  <el-form-item
                    label="地址"
                    prop="provinceValue"
                  >
                    <el-select
                      v-model="ruleForm.provinceValue"
                      class="elselect"
                      placeholder="省份"
                      @change="provinceChange"
                    >
                      <el-option
                        v-for="(item,index) in province"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item
                    class="text-center ml-20"
                    prop="cityValue"
                  >
                    <el-select
                      v-model="ruleForm.cityValue"
                      :disabled="cityStatus"
                      class="elselect"
                      placeholder="城市"
                      @change="cityChange"
                    >
                      <el-option
                        v-for="(item,index) in city"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item
                    class="ml-20"
                    prop="countyValue"
                  >
                    <el-select
                      v-model="ruleForm.countyValue"
                      :disabled="countyStatus"
                      class="elselect"
                      placeholder="区县"
                      @change="countyChange"
                    >
                      <el-option
                        v-for="(item,index) in county"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item label="公司">
                <el-input
                  v-model="ruleForm.company"
                  placeholder="请输入公司名称"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item label="其他">
                <el-input
                  v-model="ruleForm.more"
                  type="textarea"
                  :rows="5"
                  placeholder="其他您想要告诉我们的"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div
        class="submit_btn"
        @click="submitForm('ruleForm')"
      >
        立即咨询
      </div>
    </div>
  </div>
</template>

<script>
import dialogtitle from './dialogtitle';
import areac from '@/util/js/areac';

export default {
  name: 'FloatBtnPencil',
  components: { dialogtitle },
  data() {
    return {
      pendding: false,
      province: [],
      provinceIndex: null, // 省的下标
      city: [],
      cityIndex: null, // 城市的下标
      cityStatus: true,
      county: [],
      countyStatus: true,
      countyIndex: null, // 区县的下标
      ruleForm: {
        name: '',
        duty: '',
        phone: '',
        email: '',
        company: '',
        more: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        countyValue: '',
        address: '',
        areaCode: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入您的联系电话', trigger: 'blur' },
        ],
        provinceValue: [
          { required: true, message: '请选择您的省份', trigger: 'change' },
        ],
        cityValue: [
          { required: true, message: '请选择您的城市', trigger: 'change' },
        ],
        countyValue: [
          { required: true, message: '请选择您的区县', trigger: 'change' },
        ],
      },
    };
  },
  mounted() {
    this.getCity();
  },
  methods: {
    handleClose() {
      this.$emit('handleClose', 'pencil');
    },

    // 点击选中县城
    countyChange(val) {
      this.ruleForm.countyValue = this.county[val].label;
      this.ruleForm.areaCode = this.county[val].value;
    },
    // 点击选中市
    cityChange(val) {
      this.cityIndex = val;
      this.ruleForm.countyValue = '';
      this.ruleForm.areaCode = '';
      this.county = this.province[this.provinceIndex].children[val].children;
      this.countyStatus = false;
      this.ruleForm.cityValue = this.city[val].label;
      this.ruleForm.cityCode = this.city[val].value;
    },
    // 点击选中省
    provinceChange(val) {
      this.provinceIndex = val;
      this.county = '';
      this.ruleForm.cityValue = '';
      this.ruleForm.countyValue = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.areaCode = '';
      this.city = this.province[val].children;
      this.cityStatus = false;
      this.ruleForm.provinceValue = this.province[val].label;
      this.ruleForm.provinceCode = this.province[val].value;
    },
    // 获取三级联查的各个地区
    getCity() {
      this.province = areac.data.filter(item => [
        item.value,
        item.label,
      ]);
    },
    // 马上加入
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.padding) {
            this.padding = false;
            this.$ajax({
              url: '/jepaas/product/crm/customer/saveSaleLead',
              data: {
                typeCode: 'jepaas',
                name: this.ruleForm.name,
                telePhone: this.ruleForm.phone,
                corporateName: this.ruleForm.company,
                provinceName: this.ruleForm.provinceValue,
                provinceCode: this.ruleForm.provinceCode,
                cityName: this.ruleForm.cityValue,
                cityCode: this.ruleForm.cityCode,
                areaName: this.ruleForm.countyValue,
                areaCode: this.ruleForm.areaCode,
                duties: this.ruleForm.duty,
                email: this.ruleForm.email,
                describe: this.ruleForm.more,
              },
            }).then(() => {
              this.padding = false;
              this.$message.success('提交成功请静候佳音!');
              this.ruleForm.name = '';
              this.ruleForm.phone = '';
              this.ruleForm.company = '';
              this.ruleForm.provinceValue = '';
              this.ruleForm.provinceCode = '';
              this.ruleForm.cityValue = '';
              this.ruleForm.cityCode = '';
              this.ruleForm.countyValue = '';
              this.ruleForm.areaCode = '';
              this.ruleForm.duty = '';
              this.ruleForm.email = '';
              this.ruleForm.more = '';
              this.$refs[formName].resetFields();
              this.dialogFormVisible = false;
            }).catch(() => {
              this.padding = false;
            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
  .float_btn_pencil {
    padding: 35px 40px 70px;
    p,
    div {
      margin: 0;
      text-align: left;
    }
    .float_btn_pencil_content {
      width: 550px;
      .form_wrp {
        width: 100%;
        margin-top: 40px;
        .mt-20 {
          margin-top: 20px;
        }
        .ml-20 {
          .el-form-item__content {
            margin-left: 20px !important;
          }
        }
        .elselect {
          input {
            height: 40px !important;
          }
        }
      }
      .submit_btn {
        width: 200px;
        height: 50px;
        cursor: pointer;
        background: #FF3041;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        margin: 40px auto 0;
        box-shadow:2px 2px 10px 0px rgba(255,48,65,0.4);
        border-radius: 25px;
      }
    }
  }
</style>
