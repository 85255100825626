<!--
 * @Descripttion: 主目录的框架
 * @Author: 张明尧
 * @Date: 2021-02-02 13:55:31
 * @LastEditTime: 2021-03-02 11:08:54
-->
<template>
  <div class="index-main">
    <Header />
      <div class="padding-top-80">
        <router-view />
      </div>
    <Footer/>
    <FloatMenu />
  </div>
</template>

<script>
import Header from '../../components/header.vue';
import Footer from '../../components/footer.vue';
import FloatMenu from '../../components/floatMenu.vue';
export default {
  components: {
    Header,
    Footer,
    FloatMenu
  },
  data(){
    return {

    }
  }
}
</script>

<style>
@import "../../util/style/default.css";
@import "../../util/style/common/block.scss";
@import "../../util/style/common/asColor.scss";
</style>
