<template>
  <div class="float_btn">
    <el-backtop>
      <div class="float-rocket-top">
        <img src="../assets/ktdn/rocket.svg" alt="">
      </div>
    </el-backtop>
    <div
      v-for="(item, index) in btnlist"
      :key="index"
      class="btn_wrp"
    >
      <div
        class="btns"
        @click.stop="handleBtn(item.type)"
        @mouseover="mouseover(item.type)"
        @mouseout="mouseout()"
      >
        <i :class="item.icon" />
      </div>
      <Dialogwrp
        v-if="item.type != 'qq'"
        :ref="item.type"
        :type="item.type"
        :show="item.show"
        @handleClose="handleClose"
      />
    </div>
  </div>
</template>

<script>
import Dialogwrp from './components/dialogwrp';

export default {
  name: 'FloatBtn',
  components: { Dialogwrp },
  data() {
    return {
      btnlist: [
        {
          icon: 'jeicon jeicon-qq1',
          type: 'qq',
          show: false,
        },
        {
          icon: 'jeicon jeicon-wechat',
          type: 'wechat',
          show: false,
        },
        {
          icon: 'jeicon jeicon-phone',
          type: 'phone',
          show: false,
        },
        {
          icon: 'jeicon jeicon-pencil',
          type: 'pencil',
          show: false,
        },
      ],
    };
  },
  methods: {
    setVisible(visible, type) {
      this.btnlist.forEach((item) => {
        if (item.type == type) {
          item.show = visible;
        } else {
          item.show = false;
        }
      });
    },
    handleBtn(type) {
      this.setVisible(false);
      if (type == 'qq') {
        window.open('http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c');
      }
      if (['phone', 'pencil'].indexOf(type) > -1) {
        this.setVisible(true, type);
      }
    },
    mouseover(type) {
      if (type == 'wechat') {
        this.setVisible(true, type);
      }
    },
    mouseout() {
      this.btnlist.forEach((item) => {
        if (item.type == 'wechat') {
          item.show = false;
        }
      });
      // this.setVisible(false, 'wechat');
    },
    handleClose(type) {
      this.setVisible(false, type);
    },
  },
};
</script>
<style lang="scss">
.el-backtop{
  border-radius: 0;
  width: 52px;
  height: 52px;
  right: 60px!important;
  top: 250px!important;
  background-color: transparent;
  box-shadow: none;
  border: none;
  &:hover {
    background-color: transparent;
  }
  .float-rocket-top {
    img {
      width: 120px;
      height: auto;
    }
  }
}
</style>
<style lang="scss" scoped>
  .float_btn {
    position: fixed;
    // top: 75vh;
    right: 2%;
    width: 52px;
    z-index: 10;
    bottom: 100px;
    > .btn_wrp {
      width: 52px;
      height: 52px;
      margin-top:5px;
      background: #ffffff;
      position: relative;
      border-radius: 4px;
      cursor: pointer;
      box-shadow:0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      > .btns {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 300ms;
        border-radius: 4px;
        > i {
          font-size: 24px;
          color: #0358FD;
        }
        &:hover,
        &.active {
          background: #0358FD;
          > i {
            color: #ffffff;
          }
        }
      }
    }
  }
</style>
